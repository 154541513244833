import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, IconButton, Stack, Slide, useMediaQuery, useScrollTrigger } from '@mui/material';

import styles from './Header.module.scss';

const WithSlide = (props) => {
    let trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
        target: document.querySelector('#root'),
    });

    return (
        <Slide in={trigger}>
            {props.children}
        </Slide>
    );
}

export const Header = (props) => {

    const topClickHandler = (e) => {
        e.preventDefault();
        const anchor = (e.target.ownerDocument || document).querySelector('#top');
        if (anchor) anchor.scrollIntoView({block: 'center', behavior: 'smooth'});
    }
    
    const isMobile = useMediaQuery(t => t.breakpoints.down('md'));
    const head = (
        <AppBar classes={{root:styles.wrapper}} position={props.sticky?"sticky":"fixed"}>
            <Toolbar classes={{root:styles.toolbar}}>
                {isMobile &&
                    <IconButton size="small" color="primary" aria-label="to top" component="a" href="#top" onClick={topClickHandler} className={styles.top}>
                        <i className="fas fa-arrow-up" />
                    </IconButton>
                }
                <Typography variant="h4" component={Link} to="/#home" sx={{my: 2, mx: {lg:2}, fontWeight: 500}}>
                    Hola! I'm <span>Leo</span>
                </Typography>
                <Stack direction="row" flexWrap={{xs:"wrap", lg:"initial"}}>
                    <Button component={Link} to={'/#projects'} variant="contained" sx={{mr: 1, mb: 1}} endIcon={isMobile?null:<i className="far fa-code" />}>
                        Stack
                    </Button>
                    <Button component={Link} to={'/#experience'} variant="contained" sx={{mr: 1, mb: 1}} endIcon={isMobile?null:<i className="far fa-chart-network" />}>
                        Lore
                    </Button>
                    <Button component={Link} to={'/#play'} variant="contained" sx={{display: {xs: 'none', lg:'inline-flex'}, mr: 1, mb: 1}} endIcon={isMobile?null:<i className="far fa-gamepad" />}>
                        Play
                    </Button>
                    <Button component={Link} to={'/#contact'} variant="contained" sx={{mr: 1, mb: 1}} endIcon={isMobile?null:<i className="far fa-comments-alt" />}>
                        Talk
                    </Button>
                    {/*
                    <Button component={Link} to={'/#bonus'} variant="contained" sx={{mr: 1, mb: 1}} endIcon={<i className="far fa-heart" />}>
                        Bonus
                    </Button>
                    */}
                </Stack>
            </Toolbar>
        </AppBar>
    );

    if (props.sticky) return head;
    else return <WithSlide>{head}</WithSlide>;
}