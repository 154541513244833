import React, { useState, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';

import styles from './Avatar.module.scss';

export const Avatar = (props) => {
    const [loading, setLoading] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [lastPoint, setLastPoint] = useState({x: null, y: null, direction: null});

    const mouseMoveHandler = useCallback(e => {
        const leftOrRight = e.clientX > lastPoint.x ? 'right' : e.clientX < lastPoint.x ? 'left' : 'none';
        const upOrDown = e.clientY > lastPoint.y ? 'down' : e.clientY < lastPoint.y ? 'up' : 'none';

        if (!loading && lastPoint.direction !== `${upOrDown}-${leftOrRight}`){
            setLoading(true);

            setCursorPosition(prev=>{
                if (prev > 0) return 0;
                else return window.innerWidth / 2;
            });

            setLastPoint({x: e.clientX, y: e.clientY, direction: `${upOrDown}-${leftOrRight}`});

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [lastPoint, loading]);

    useEffect(() => {
        window.addEventListener('mousemove', mouseMoveHandler);
        return () => {
            window.removeEventListener('mousemove', mouseMoveHandler);
        }
    }, [mouseMoveHandler]);

    return (
        <Box className={styles.leo} onMouseDown={()=>setClicked(true)} onMouseUp={()=>setClicked(false)} onBlur={()=>setClicked(false)}>
            <div style={{ transform: `translateX(${cursorPosition * 0.005}px)` }} />
            <div style={{ transform: `translateX(${cursorPosition * 0.0075}px)` }} className={clicked?styles.click:null} />
            <div style={{ transform: `translateX(${cursorPosition * 0.01}px)` }} />
        </Box>
    );
}