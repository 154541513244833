import { useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToHashElement = (props) => {
  	const location = useLocation();

  	const hashElement = useMemo(() => {
		const hash = location.hash;
		if (hash) {
	  		const element = document.querySelector(hash);
	  		return element;
		} else return null;
  	}, [location]);

  	useEffect(() => {
		if (hashElement) {
	  		hashElement.scrollIntoView({
				behavior: "smooth",
				inline: "nearest",
	  		});
		}
  	}, [hashElement]);

  	return null;
}