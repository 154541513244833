import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Button } from '@mui/material';

import useIsInViewport from '../../../components/useIsInViewport';

import APIProjects from '../../../api/projects';
import styles from './Projects.module.scss';

export const Projects = (props) => {
    const navigate = useNavigate();
    const ref = useRef();
    const isInViewport = useIsInViewport(ref);

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const _getProjects = async () => {
            const res = await APIProjects.get();
            if (res) setProjects(res);
        }
        _getProjects();

        return () => {
            setProjects([]);
        }
    }, []);

    const clickHandler = (item) => (e) => {
        e.preventDefault();
        navigate(`/app/${item.toLowerCase()}`);
    }

    return (
        <Grid container spacing={2} ref={ref} className={`with-fade ${isInViewport?'fade-in':''}`}>
            <Grid item xs={12}>
                <Typography variant="h1">The Stack</Typography>
                <p>
                    My stack of choice is ReactJS, PHP, and MySQL. But I'm also an expert with NodeJs, MongoDB, Python, and Solidity.
                </p>
                <ul>
                    <li>
                        For the front-end, I use ReactJS along with Redux and CSS frameworks like Material-UI, Bootstrap, or Tailwind.
                    </li>
                    <li>
                        For the back-end, I prefer Laravel or Express.
                    </li>
                </ul>
                <p>
                    Here are some of the projects I've worked on over the years. 
                    Some are still in use today, others are just for fun.
                </p>
                <p>
                    You can also check out my <Button type="text" href="https://github.com/leo-castro" target="_blank" rel="noreferrer" startIcon={<i className="fab fa-github"/>}>GitHub</Button>
                </p>
            </Grid>
            {projects.map((item, i) => (
                <Grid item key={`project-${i}`} xs={12} lg={6} xl={4}>
                    <Card variant="outlined" component={Button} onClick={clickHandler(item.title)} className={styles.card} sx={{'&:hover':{backgroundColor:item.color, backgroundImage: item?.image || undefined}}}>
                        <CardContent>
                            <Typography variant="h3" component="div">{item.title}</Typography>
                            <Typography variant="body2">{item.description}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}