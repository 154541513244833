import { useState, useEffect } from 'react';

export const useIsInViewport = (ref) => {
    const [isInViewPort, setIsInViewport] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const { top } = ref.current.getBoundingClientRect();
            return setIsInViewport(window.innerHeight - top > 100);
        }
  
        window.addEventListener("scroll", handleScroll);
  
        return () => window.removeEventListener("scroll", handleScroll);
    }, [ref, isInViewPort]);

    return isInViewPort;
}