import React, { useState } from 'react';
import {Box, Paper, IconButton} from '@mui/material';
import styles from './Carousel.module.scss';

export const Carousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    let touchStartX = 0;

    const nextHandler = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }

    const prevHandler = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    }

    const scrollHandler = (e) => {
        if (e.deltaY > 0) nextHandler();
        else prevHandler();
    }

    const touchStartHandler = (e) => {
        touchStartX = e.touches[0].clientX;
    }

    const touchEndHandler = (e) => {
        const touchEndX = e.changedTouches[0].clientX;
        const touchDiff = touchStartX - touchEndX;

        if (touchDiff > 0) nextHandler();
        else prevHandler();
    }

    if (!images || images.length === 0) return null;

    return (
        <Box className={styles.carousel} onWheel={scrollHandler} onTouchStart={touchStartHandler} onTouchEnd={touchEndHandler}>
            <IconButton size="large" color="primary" aria-label="previous image" className={styles["prev-button"]} onClick={prevHandler}>
                <i className="fas fa-chevron-left" />
            </IconButton>
            <Box className={styles["image-container"]}>
                {images.map((image, index) => {
                    const isActive = index === currentIndex;
                    const prev = index === (currentIndex - 1 + images.length) % images.length;
                    const next = index === (currentIndex + 1) % images.length;

                    return (
                        <Paper key={index} component="img" src={image.img} alt="" className={`${isActive?styles.active:''} ${prev?styles.prev:''} ${next?styles.next:''}`} />
                    );
                })}
            </Box>
            <IconButton size="large" color="primary" aria-label="next image" className={styles["next-button"]} onClick={nextHandler}>
                <i className="fas fa-chevron-right" />
            </IconButton>
            <Box className={styles.dots}>
                {images.map((_, index) => (
                    <span key={index} className={index === currentIndex ? styles.active : ''} onClick={()=>setCurrentIndex(index)}/>
                ))}
            </Box>
        </Box>
    );
};

export default Carousel;