import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Container, Grid, Box, useMediaQuery, IconButton } from '@mui/material';

import Header from '../../components/Header';
import Carousel from '../../components/Carousel';

import APIProjects from '../../api/projects';
import styles from './Project.module.scss';

export const Project = (props) => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery(t => t.breakpoints.down('md'));
    const {name} = useParams();

    const [project, setProject] = useState();

    useEffect(() => {
        const _getProject = async () => {
            try{
                const res = await APIProjects.get({name});
                if (res) setProject(res);
            }catch(e){
                navigate('/');
            }

        }
        _getProject();

        return () => {
            setProject(null);
        }
    }, [name, navigate]);

    if (!project) return null;

    return (
        <>
            {isMobile &&
                <Header sticky={isMobile}/>
            }
            <div id="top" />
            <Grid container spacing={0}>
                <Grid item xs={12} lg={6} className={styles.header}>
                    <Container maxWidth="lg" sx={{p:0}}>
                        <Grid container>
                            <Grid item sx={{display:"flex"}} alignItems="center">
                                <IconButton size="large" color="primary" aria-label="go back" component={Link} to="/#projects" className={styles.back}>
                                    <i className="fas fa-arrow-left" />
                                </IconButton>
                            </Grid>
                            <Grid item xs>
                                <div className={`${styles.logo} ${styles[name.replace(/[\s.]+/g, '').toLowerCase()]}`}/>
                            </Grid>
                        </Grid>
                        
                        <Grid container spacing={5}>
                            <Grid item xs={12} lg={6}>
                                <Box sx={{my: {xs: 0, lg: 0}}} dangerouslySetInnerHTML={{__html: project.details}} />
                            </Grid>
                            <Grid item xs={12} lg={6} className={styles["stack-container"]}>
                                <Box dangerouslySetInnerHTML={{__html: project.stack}} />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
                <Grid item xs={12} lg={6}>
                    {!isMobile &&
                        <Header sticky={!isMobile}/>
                    }
                    <Box className={styles.content}>
                        {project.images &&
                            <Carousel images={project.images} />
                        }
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}