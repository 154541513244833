import React, {useRef, useEffect, useState} from 'react';
import { Container, Grid, Typography, Button, Box } from '@mui/material';

import ScrollToHashElement from '../../components/ScrollToHashElement';
import Header from '../../components/Header';
import Avatar from './Avatar';
import Experience from './Experience';
import Projects from './Projects';
import Contact from './Contact';

import styles from './Home.module.scss';

export const Home = (props) => {
    const ref = useRef();

    const [hashContainer, setHashContainer] = useState(null);

    useEffect(() => {
        if (ref.current) setHashContainer(ref.current);
    }, []);

    return (
        <>
            {hashContainer &&
                <ScrollToHashElement containerRef={hashContainer} />
            }
            <Header />
            <div id="top" />
            <Grid container className={styles.wrapper} spacing={0}>
                <Grid item xs={12} lg={6}>
                    <Avatar />
                </Grid>
                <Grid item xs={12} lg={6} sx={{p:5}} className={styles.main}>
                    <div ref={ref}>
                        <Container className={styles.container} id="home">
                            <Typography variant="h5" component="span">
                                Hola! My name is
                            </Typography>
                            <Typography variant="h1" component="h1" classes={{h1:styles.name}}>
                                Leo
                            </Typography>
                            <p>
                                I'm a Full Stack Software Engineer with a knack for turning caffeine-fueled ideas into digital realities. 
                                From conceptualization to deployment, I relish the challenge of bringing complex projects to life.
                            </p>
                            <p>
                                So grab your wizard hat, and let the UI/UX magic begin!
                            </p>
                            
                            <Box sx={{my: 3}}>
                                <Button variant="contained" href="#projects" sx={{mr: 1, mb: 1}} endIcon={<i className="far fa-code" />}>
                                    Stack
                                </Button>
                                <Button variant="contained" href="#experience" sx={{mr: 1, mb: 1}} endIcon={<i className="far fa-chart-network" />}>
                                    Lore
                                </Button>
                                <Button variant="contained" href="#play" sx={{display: {xs: 'none', lg:'inline-flex'}, mr: 1, mb: 1}} endIcon={<i className="far fa-gamepad" />}>
                                    Play
                                </Button>
                                <Button variant="contained" href="#contact" sx={{mr: 1, mb: 1}} endIcon={<i className="far fa-comments-alt" />}>
                                    Talk
                                </Button>
                                {/*
                                <Button variant="contained" href="#bonus" sx={{mr: 1, mb: 1}} endIcon={<i className="far fa-heart" />}>
                                    Bonus
                                </Button>
                                */}
                            </Box>
                        </Container>

                        <Container className={styles.container} id="projects">
                            <Projects/>
                        </Container>

                        <Container className={styles.container} id="experience">
                            <Experience/>
                        </Container>

                        <Container className={styles.container} id="contact">
                            <Contact/>
                        </Container>
                    </div>

                </Grid>
            </Grid>
        </>
    );
}