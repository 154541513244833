import React, { useRef } from 'react';
import { Grid, Typography, Button, TextField } from '@mui/material';
import useIsInViewport from '../../../components/useIsInViewport';

const funnyContacts = [
    {
        name: 'Chuck Norris',
        email: 'pushingtheworlddown@chucknorris.com',
    },
    {
        name: 'Tony Stark',
        email: 'tony@starkindustries.com',
    },
    {
        name: 'Michael Scott',
        email: 'mscott@dundermifflin.com',
    },
    {
        name: 'Sheldon Cooper',
        email: 'drcooper@caltech.edu',
    },
    {
        name: 'Homer Simpson',
        email: 'chunkylover53@aol.com',
    },
    {
        name: 'Peter Griffin',
        email: 'pumpkineater69@twitter.com',
    },
    {
        name: 'Dwight Schrute',
        email: 'dschrute@dundermifflin.com',
    },
    {
        name: 'Murray Bauman',
        email: '7c7299e011ee0242ac120002@proton.me'
    },
    {
        name: 'Ron Burgundy',
        email: 'ron@channelfournews.myspace.com',
    },
    {
        name: 'Willy Wonka',
        email: 'willardwilbur@aol.com',
    },
    {
        name: 'Taylor Swift',
        email: 'taytay@taylorswift.com',
    },
    {
        name: 'Miley',
        email: 'notme@mileycyrus.com',
    },
    {
        name: 'Regina Phalange',
        email: 'therealreginaphalange@lycos.com',
    },
    {
        name: 'Kramer',
        email: 'justkramer01@yahoo.com',
    },
    {
        name: 'John Snow',
        email: 'idontwantit@gmail.com',
    },
];



export const Contact = (props) => {
    const ref = useRef();
    const isInViewport = useIsInViewport(ref);

    const placeholder = funnyContacts[Math.floor(Math.random() * funnyContacts.length)];

    return (
        <Grid container spacing={2} ref={ref} className={`with-fade ${isInViewport?'fade-in':''}`}>
            <Grid item xs={12}>
                <Typography variant="h1">Let's Talk!</Typography>
                <p>
                    I'm always looking for new challenges and opportunities to learn and grow.<br/>
                    If you have a project in mind, want to talk about games, superhero movies, or just want to say hi, feel free to contact me.
                </p>
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField id="name" name="name" label="Name" placeholder={placeholder.name} variant="outlined" required fullWidth />
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField id="email" name="email" label="Email" placeholder={placeholder.email} type="email" variant="outlined" required fullWidth />
            </Grid>
            <Grid item xs={12}>
                <TextField id="message" name="message" label="Message" variant="outlined" multiline rows={4} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" endIcon={<i className="far fa-paper-plane" />}>
                    Send
                </Button>
            </Grid>
        </Grid>
    );
}