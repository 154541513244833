import xitrus1 from "../assets/images/xitrus/x1.png";
import xitrus2 from "../assets/images/xitrus/x2.png";
import xitrus3 from "../assets/images/xitrus/x3.png";
import xitrus4 from "../assets/images/xitrus/4.jpg";
import xitrus5 from "../assets/images/xitrus/x5.png";

const projects = [
    {
        title: "Xitrus",
        description: "A web-based invoice management system.",
        url: "https://xitr.us",
        color: "#0098DA",
        stack: `
            <p>
                I started developing Xitrus back in the year 2000. It was originally a desktop application written in Visual FoxPro that used VFP's DBF database format (which got corrupted every other day). In 2008 I ported it to PHP using a rudimentary PHP framework that I built. Then in 2017 I created a better PHP framework using the MVC pattern and ported Xitrus again along with a renewed user interface using material design.
            </p>
            <p>
                <br/>
                <b>The Stack</b>
            </p>
            <ul>
                <li>PHP</li>
                <li>MySQL</li>
                <li>HTML</li>
                <li>CSS</li>
                <li>JavaScript</li>
                <li>Materialize</li>
                <li>C#</li>
            </ul>
        `,
        details: `
            <p>
                Xitrus is a web-based invoice management system for latin american businesses, which means it's 100% in spanish. It allows you to create and manage invoices, quotes, purchase orders, customers, providers, products, and services. It also allows you to create and manage users and their permissions, accounts payables, and accounts receivables, among other things like managing your bank accounts and their transactions.
            </p>
            <p>
                In latin america there are different ways each country collects their taxes, for example in Panama, you must use a fiscal printer, while in Mexico you must use fiscal digital certificates per invoice. For fiscal printer support I developed a service-like desktop application in C# that polls the database for new invoices and sends them to the printer.
            </p>
        `,
        images: [{img:xitrus1, rows:3, cols: 3}, {img:xitrus2}, {img:xitrus3}, {img:xitrus4, rows:2, cols:2}, {img:xitrus5}]
    },
    {
        title: "Wizzee",
        description: "A CMS that uses AI to create websites in seconds.",
        url: "https://wizzee.net",
        color: "#d90368",
        stack: `
            <p>
                Wizzee is my most recent project. It was built from the ground up using Laravel for API endpoints and React with MUI (Material UI) for the user interface. It uses MySQL for the database, Redis for caching, Microsoft Azure GPT-4 models for the AI chatting functionality, and Stable Diffusion for AI image generation.
            </p>
            <p>
                <br/>
                <b>The Stack</b>
            </p>
            <ul>
                <li>React</li>
                <li>Redux</li>
                <li>React Router</li>
                <li>Axios</li>
                <li>Javascript</li>
                <li>MUI</li>
                <li>Laravel</li>
                <li>PHP</li>
                <li>MySQL</li>
                <li>Redis</li>
                <li>Microsoft Azure</li>
                <li>OpenAI</li>
            </ul>
        `,
        details: `
            <p>
                Wizzee is not your regular drag & drop CMS. Instead you chat with a trained AI model that will build your website in seconds. Depending on your plan you may create one website with up to five pages, or five websites with unlimited pages.
            </p>
            <p>
                You can change the theme for your entire website, or just change the layout of a section in a page with a simple click, for example, an image gallery can be turned into a slider, a carousel, or a masonry grid. You can also change the content of each section manually or by using the AI assitant.
            </p>
            <p>
                Wizzee also lets you create logos, and custom AI images for your pages through its chat interface. You can also create a blog, a store, and multiple types of forms.
            </p>
        `,
    },
    {
        title: "SiteBoss",
        description: "A SaaS platform for many types of businesses.",
        url: "https://siteboss.net",
        color: "#662d91",
        stack: `
            <p>
                SiteBoss is a huge project which I'm still a part of. I was part of the team that built the front-end from the ground up, the POS system, the CMS, as well as some crucial parts of the back-end. 
            </p>
            <p>
                The front-end is a React application that uses Bootstrap for the UI as well as many other libraries for things like state management, routing, drag and drop, and more.
            </p>
            <p>
                The back-end is in Laravel/Lumen and uses MySQL for the database, email queues, and a few other things.
            <p>
                <b>The Stack</b>
            </p>
            <ul>
                <li>React</li>
                <li>Redux</li>
                <li>React Router</li>
                <li>Axios</li>
                <li>Javascript</li>
                <li>Bootstrap</li>
                <li>Laravel</li>
                <li>PHP</li>
                <li>MySQL</li>
                <li>Docker</li>                
            </ul>
        `,
        details: `
            <p>
                SiteBoss is a SaaS platform that allows you to manage many types of businesses, from restaurants to athletic facilities. It's multi-tenant, which means that you can manage multiple companies from a single account. Among its many features, it allows you to manage locations, events, memberships, subscriptions, products, services, create websites through its CMS, sell products and services through its POS system or through its e-commerce platform, accept credit card payments, and more.
            </p>
            <p>
                The CMS, which is a huge project on its own, allows you to create websites using an intuitive drag and drop interface. It also has some AI features that allow you to add content to your website in seconds, like rewriting a title, adding a blog post, or creating a terms and conditions page.
            </p>
        `       
    },
    {
        title: "ChainHounds",
        description: "A racing game powered by the Ethereum blockchain.",
        url: "https://chainhounds.com",
        color: "#00afb9",
        stack: `
            <p>
                ChainHounds started as a web3 experiment in 2021. Back then I was learning Solidity and NFTs were all the rage, so I decided to create a web-based racing game that used NFTs and the blockchain to make it 100% auditable by anyone.
            </p>
            <p>
                The front-end was built using React, websockets, hot wallet libraries like MetaMask, and Coinbase, and a few others. The back-end was built using Laravel, NodeJs, Redis, and MySQL. The blockchain part uses Solidity deployed on the Ethereum and Polygon networks.
            </p>
            <p>
                <b>The Stack</b>
            </p>
            <ul>
                <li>React</li>
                <li>Redux</li>
                <li>React Router</li>
                <li>MUI</li>
                <li>Websockets</li>
                <li>Axios</li>
                <li>Javascript</li>
                <li>Laravel</li>
                <li>PHP</li>
                <li>MySQL</li>
                <li>Redis</li>
                <li>NodeJs</li>
                <li>Solidity</li>
                <li>Ethereum</li>
                <li>Polygon</li>
                <li>Web3</li>
                <li>MetaMask</li>
            </ul>
        `,
        details: `
            <p>
                ChainHounds is set in a post-nuclear future that completely changed the world and society as we know it. The world is now divided into 6 factions, each one with its own set of rules and beliefs. Some have advanced technology, while others decided to go back to the basics.
            </p>
            <p>
                The game is about racing your ChainHounds (which are NFTs) against other players to win prizes. You can equip them with items that give them special abilities, like speed boosts, protective shields, among others. These boosters are also NFTs that you can buy, sell, or trade in NFT marketplaces.
            </p>
            <p>
                Since there are no true randomn numbers in the blockchain, I deployed a smart contract that uses the latest Ethereum mined block and assigns values to each hound in the race based on transactions and other factors.
            </p>
        `
    },
    {
        title: "Showz.Live",
        description: "A live streaming platform for artists and performers.",
        url: "https://showz.live",
        color: "#453390",
        image: "linear-gradient(to bottom right, #453390 40%, #E5375E 100%)",
        stack: `
            <p>
                Showz.Live was built using two custom frameworks for the front-end and back-end. The front-end framework, called GotBox, was built with Javascript, and it works sort of like a mini React. I was a collaborator in developing the GotBox framework. As for the back-end, it was built using a custom PHP framework that I built... yes, the same one I used for Xitrus.
            </p>
            <p>
                The videos are hosted on AWS S3, and the live streams are broadcasted using AWS MediaLive.
            <p>
                <br/>
                <b>The Stack</b>
            </p>
            <ul>
                <li>PHP</li>
                <li>MySQL</li>
                <li>HTML</li>
                <li>CSS</li>
                <li>JavaScript</li>
                <li>Materialize</li>
                <li>AWS</li>
            </ul>
        `,
        details: `
            <p>
                Showz.Live is a live streaming platform for artists and performers. It allows you to create a channel and stream your performances to your fans. You can also sell tickets to your shows, or accept donations from your fans.
            </p>
            <p>
                The platform was built right before the pandemic hit, and it was meant to be used by artists and performers to stream their shows to their fans without the need of large gatherings. It was also meant to be used by venues to stream their shows to their customers.
            </p>
        `
    },
    {
        title: "The Bifröst",
        description: "A fun experiment that mixes weird lore with generative art.",
        color: "#000",
        image: "linear-gradient(to right, #f44336, #673ab7, #009688, #4caf50, #ff9800)",
        stack: `
            <p>
                The UI for the Bifrost was thought out to be hyper minimalistic so that the focus is on the generative art that covers the whole page.
            </p>
            <p>
                The generative art is generated using a custom algorithm that fetches the latest mined Ethereum block to generate a unique set of images that are animated on an HTML canvas.
            </p>
            <p>
                <br/>
                <b>The Stack</b>
            </p>
            <ul>
                <li>React</li>
                <li>MUI</li>
                <li>Axios</li>
                <li>Javascript</li>
                <li>Solidity</li>
                <li>Ethereum</li>
                <li>Web3</li>
                <li>MetaMask</li>
            </ul>
        `,
        details: `
            <p>
                The Bifrost is one of those fun weekend projects that I like to do from time to time. It's an experiment using generative art and some dark lore that mixes norse with mayan mythology with a touch of H.P. Lovecraft's Cthulhu mythos. Crazy, right?
            </p>
            <p>
                As you are seeing the bridge get built in front of you, you are shown a small piece of the lore that explains what's going on, what you're seeing and hearing, and a hint of what's to come.
            </p>
            <p>
                If you wish to, you can connect an Ethereum wallet (like MetaMask or Coinbase), and mint a piece of the bridge, which will be stored in the blockchain. You can then sell it or trade it in any NFT marketplace, or keep it for the next chapter of the experiment.
            </p>
        `
    },
    {
        title: "Sounds of Eth",
        description: "Another experiment that makes the Ethereum network go \"om\".",
        color: "#f44336",
        stack: `
            <p>
                The UI for this project/experiment was meant to be very simple. It's built using React and MUI (Material UI) for the UI, and Axios to fetch blockchain data from Etherscan.
            </p>
            <p>
                In the future I plan to add more features to this experiment, like allowing users to mint the sound they're listening to as an NFT, or maybe read data from other blockchains like Polygon, Solana, or Cardano.
            </p>
            <p>
                <br/>
                <b>The Stack</b>
            </p>
            <ul>
                <li>React</li>
                <li>MUI</li>
                <li>Axios</li>
                <li>Javascript</li>
                <li>Ethereum</li>
                <li>Web3</li>
            </ul>
        `,
        details: `
            <p>
                Sounds of Eth, or Sounds of Ethereum was a little fun project I created while bored on an international flight. It takes the latest Ethereum block and loops through each transaction in the block to generate a unique sound for them. Each sound would be a pentatonic frequency in the range of 60-80hz with a rythm pattern based on the transaction's hash. The duration of each sound is based on the transaction's value.
            </p>
            <p>
                The result should be a somewhat harmonic sound that you can listen to without going crazy. Or maybe not. Music is subjective after all.
            </p>
        `
    }
];


const get = (params) => {
    return new Promise((resolve, reject) => {
        if (params?.name){
            const project = projects.find(p => p.title.toLowerCase() === params.name.toLowerCase());
            if (project) resolve(project);
            else reject("Project not found.");
        }

        resolve(projects);
    });
}

const Projects = {get}
export default Projects;