import React, { useRef } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { Timeline, TimelineItem, timelineItemClasses, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab';

import useIsInViewport from '../../../components/useIsInViewport';

const experience = [
    {
        year: "Oct. 1980",
        title: "Born-2B-Dev",
        description: "I opened my eyes and saw a screen. I was destined to be a developer!",
    },
    {
        year: "1990-ish",
        title: "Rem qBasic",
        description: "Dad brought home a computer. I started learning MS-DOS commands, one of which was qbasic.com (or was it .exe?). I was hooked!",
    },
    {
        year: "1998-ish",
        title: "Database 101",
        description: "I started working for a small company as a developer intern. Learned Clipper, Visual FoxPro, and the amazing world of databases. I also destroyed a production database... But had a backup!!!",
    },
    {
        year: "Dec. 2000",
        title: "Y2K Bug Ended the World",
        description: "Although that didn't happen, I created a CRM and invoice management system called <a href='https://xitr.us' target='_blank'>Xitrus</a> using PHP and MySQL. This system is still in use today throughout Latin America.",
    },
    {
        year: "Oct. 2008",
        title: "Start-up Again",
        description: "I joined two friends in their start-up called Avacom. We re-branded the whole thing, created custom CMSes and e-commerce solutions. I was in charge of the development team and the system architecture.",
    },
    {
        year: "Jan. 2017",
        title: "Freelance Experimenting",
        description: "I wanted to have fun again, so I started working as a freelance Full Stack Developer. I also developed my own MVC framework using PHP and ported Xitrus to it (although nowadays I just stick to Laravel or NodeJS). Fun times!",
    },
    {
        year: "May. 2022",
        title: "A WizardPig Appears",
        description: "Moved to the US and became a full-time Senior Software Engineer for a company called <a href='https://wizardpig.com' target='_blank'>WizardPig</a> where I met the most amazing people in the world, and get to work on super cool projects, some involving AI and ML.",
    },
];

export const Experience = (props) => {
    const ref = useRef();
    const isInViewport = useIsInViewport(ref);
    const isMobile = useMediaQuery(t => t.breakpoints.down('md'));

    return (
        <Grid container spacing={2} ref={ref} className={`with-fade ${isInViewport?'fade-in':''}`}>
            <Grid item xs={12}>
                <Typography variant="h1">The Lore</Typography>
                <p>
                    I was born in Venezuela before the internet was a thing. I currently live in Massachusetts, USA, and
                    speak fluent Spanish and English. I love cooking yummy food, playing video games, comic books, and 
                    all things tech. But the thing I love the most is being a dad to my two amazing kids, and a husband 
                    to my beautiful wife.
                </p>
                <p>
                    Here's a timeline of my career as a developer. From the early days of multicolored mohawks to the latest AI/ML projects.
                </p>
            </Grid>
            <Grid item xs={12}>
                <Timeline position={isMobile?"right":"alternate"} sx={{p:isMobile?0:'inherit', [`& .${timelineItemClasses.root}:before`]: {flex: isMobile?0:'inherit', padding: isMobile?0:'inherit'}}}>
                    {experience.map((item, i) => (
                        <TimelineItem key={`time-${i}`}>
                            {!isMobile && 
                                <TimelineOppositeContent color="text.secondary">
                                    <Typography variant="h4">{item.year}</Typography>
                                </TimelineOppositeContent>
                            }
                            <TimelineSeparator>
                                <TimelineDot variant="outlined" color="error"/>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                {isMobile &&
                                    <Typography variant="caption" component="div" color="text.secondary">{item.year}</Typography>
                                }
                                <Typography variant="h4" classes={{h4:"bold"}} sx={{fontSize:'inherit'}}>{item.title}</Typography>
                                <Typography variant="body1" component="div" sx={{mb:3}} dangerouslySetInnerHTML={{__html: item.description}} />
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </Grid>
        </Grid>
    );
}