export const theme = () => {
    const paletteType = "light";

    return ({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
        spacing: (factor) => `${0.5 * factor}rem`,
        typography: {
            fontFamily: ['Lora'].join(','),
            fontSize: 16,
            button: {
                fontFamily: ['Rubik'].join(','),
                textTransform: "none",
                fontWeight: 400,
                wordSpacing: 1,
                letterSpacing: 0,
            },
        },	
        palette: {
            primary: {
                main: '#000',
            },
            secondary: {
                main: '#fff',
            },
            tertiary: {
                main: '#0288d1',
            },
            background: {
                default: paletteType === 'dark' ? '#000' : '#fafafa',
            },            
            contrastThreshold: 4.5,
            tonalOffset: 0.2,
            mode: paletteType,
        },
        components: {
            MuiMenu: {
                styleOverrides: {
                    paper: {
                        "&::-webkit-scrollbar": {
                            width: "5px",
                        },
                        "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                        },   
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9e9e9e",
                        }
                    }
                }
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        "&::-webkit-scrollbar": {
                            width: "5px",
                        },
                        "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                        },   
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9e9e9e",
                        }
                    }
                }
            },
            MuiBreadcrumbs: {
                styleOverrides: {
                    root: {
                        fontSize: '0.85rem',
                        marginBottom: '0.5rem',
                        marginTop: '0.5rem',
                    },
                }
            },
            MuiGrid: {
                styleOverrides: {
                    container: {
                        paddingRight: '1rem',
                        [`@media (min-width:0px)`]: {
                            paddingRight: '0',
                        },
                        [`@media (min-width:300px)`]: {
                            paddingRight: '0',
                        },
                        [`@media (min-width:600px)`]: {
                            paddingRight: '0',
                        },
                        [`@media (min-width:900px)`]: {
                            paddingRight: '0',
                        },
                        [`@media (min-width:1200px)`]: {
                            paddingRight: '0',
                        },
                    },
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontFamily: ['Lora'].join(','),
                    },
                    head: {
                        fontWeight: 600,
                    },
                }
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontFamily: ['Lora'].join(','),
                    },
                    h1: {
                        fontFamily: ['Rubik'].join(','),
                        fontSize: 56,
                        fontWeight: 700,
                        lineHeight: 1.2,
                        '@media (min-width:0px) and (max-width:900px)': {
                            fontSize: 32,
                        }
                    },
                    h2: {
                        fontFamily: ['Rubik'].join(','),
                        fontSize: 32,
                        fontWeight: 700,
                        lineHeight: 1.2,
                    },
                    h3: {
                        fontFamily: ['Rubik'].join(','),
                        fontSize: 24,
                        fontWeight: 700,
                        lineHeight: 1.2,
                    },
                    h4: {
                        fontFamily: ['Rubik'].join(','),
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: 1.2,
                    },
                    h5: {
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: 1.2,
                    },
                    h6: {
                        fontFamily: ['Rubik'].join(','),
                        fontSize: 14,
                        fontWeight: 700,
                        lineHeight: 1.2,
                    },                    
                }
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#eee',
                        marginBottom: '1rem',
                        borderRadius: '8px',
                        boxShadow: 'none',
                        padding: "0 1rem",
                        textAlign: 'left',
                        '&:before': {
                            display: 'none',
                        },
                        '& .MuiTypography-h1':{
                            fontSize: '1.25rem',
                            fontWeight: 500,
                        }                        
                    },
                },
            },

            MuiCard: {
                styleOverrides: {
                    root: {
                        fontFamily: ['Lora'].join(','),
                        textAlign: 'left',
                        padding: '2rem',
                    },
                },
            },
            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        fontFamily: ['Rubik'].join(','),
                    },
                    title: {
                        fontSize: '1.5rem',
                        fontWeight: 900,
                        lineHeight: 1.2,
                    },
                },
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        fontFamily: ['Lora'].join(','),
                        flex: '1',
                        '& .MuiTypography-h1':{
                            fontSize: '2.5rem',
                            fontWeight: 900,
                        }
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        fontFamily: ['Rubik'].join(','),
                        backgroundColor: 'transparent',
                        marginBottom: "0.5rem",
                    },
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        fontFamily: ['Rubik'].join(','),
                        '& .MuiFormHelperText-root': {
                            fontFamily: ['Lora'].join(','),
                            fontSize: '0.75rem',
                            fontWeight: 400,
                            lineHeight: 1.66,
                            backgroundColor: 'transparent',
                        },
                        '& .MuiInputBase-root': {
                            fontFamily: ['Rubik'].join(','),
                            backgroundColor: '#fff',
                        },
                        '& .MuiInputLabel-root': {
                            fontFamily: ['Rubik'].join(','),
                        },
                    },
                }
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'transparent',
                        backgroundImage: 'none',
                        color: '#000',
                        boxShadow: 'none',
                    },
                },
            },
            MuiIconButton: {
                variants: [{
                    props: { size: 'xxs' },
                    style: {
                        fontSize: '0.85rem',
                        width: 24,
                        height: 24,
                    },
                }],                
                styleOverrides: {
                    root: {
                        width: 40,
                        height: 40,
                    },
                    sizeSmall: {
                        fontSize: "0.85rem",
                        width: 32,
                        height: 32,
                    },
                    sizeMedium: {
                        fontSize: "1rem",
                        width: 40,
                        height: 40,
                    },
                }
            },
            MuiButton: {
                variants: [{
                    props: { size: 'XL' },
                    style: {
                        padding: '1rem 2rem',
                        fontSize: '1.2rem',
                    },
                }],
                styleOverrides: {
                    /*
                    outlined: {
                        backgroundColor: '#131515',
                        borderColor: '#eee',
                        borderWidth: 2,
                        color: '#eee',
                        fontWeight: 400,
                        '&:hover': {
                            backgroundColor: '#000',
                            borderColor: '#fff',
                            color: '#fff',
                            borderWidth: 2,
                        },
                    },
                    sizeMedium: {
                        margin: '0 0.5rem 0.5rem 0',
                    },
                    sizeLarge: {
                        fontSize: '1.2rem',
                        padding: '1rem 2rem',
                    },
                    sizeSmall: {
                        fontSize: '0.8rem',
                        padding: '0.5rem 1rem',
                        fontWeight: 400,
                        margin: '0 0.5rem 0.5rem 0',
                    },
                    */
                    iconSizeLarge: {
                        "& > *:first-of-type": {
                            fontSize: '1rem',
                        }
                    }
                },
            },
        }
    });
}