import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Home from './containers/Home';
import Project from './containers/Project';

import './assets/css/all.min.css';
import './assets/css/style.scss';

import {theme as baseTheme} from './theme';

const theme = createTheme(baseTheme());

const App = () => {
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/app/:name" element={<Project />} />
					</Routes>
				</BrowserRouter>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default App;